var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"thermal"}},_vm._l((_vm.pages),function(page){return _c('div',{key:'page' + page,staticClass:"page",attrs:{"size":"A4"}},[_c('v-row',{staticClass:"pt-2 align-center title"},[_c('v-col',{staticClass:"d-flex flex-row align-center justify-center"},[_c('v-avatar',{attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":"https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"}})],1),_c('b',[_vm._v("Rede Casas das Tintas")])],1)],1),_c('hr',{staticClass:"my-4"}),_c('v-row',{staticClass:"text-center my-2"},[_c('v-col',[_c('h2',{staticClass:"subtitle"},[_vm._v("Extrato de Débitos "+_vm._s(_vm.date))])])],1),_c('v-row',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Dados Do Cliente")]),_c('div',[_c('span',[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(_vm.debit_statement.customer.code)+" -"+_vm._s(_vm.debit_statement.customer.name)+" ")])])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"page":page,"headers":_vm.headers,"items":_vm.getItems(page),"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.issue_date))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.due_date))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.value))+" ")]):_vm._e()]}},{key:"item.debt_balance",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.value - item.paid_value))+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"text-right pr-3",attrs:{"cols":"6"}},[_c('div',[_c('b',[_vm._v("Total de Original")]),_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.debit_statement.total))+" ")]),_c('div',[_c('b',[_vm._v("Total A pagar")]),_vm._v(" "+_vm._s(_vm.$format.decimal(_vm.debit_statement.open_total))+" ")])])],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }