<template>
  <div id="thermal">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-2 align-center title">
        <v-col class="d-flex flex-row align-center justify-center">
          <v-avatar size="60">
            <v-img
              src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>

          <b>Rede Casas das Tintas</b>
        </v-col>
      </v-row>

      <hr class="my-4" />

      <v-row class="text-center my-2">
        <v-col>
          <h2 class="subtitle">Extrato de Débitos {{ date }}</h2>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-space-between align-center mb-2">
        <v-col cols="12">
          <b>Dados Do Cliente</b>
          <div>
            <span>
              <b>Nome:</b> {{ debit_statement.customer.code }} -{{
                debit_statement.customer.name
              }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :page="page"
            :headers="headers"
            :items="getItems(page)"
            :items-per-page='-1'
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.issue_date`]="{ item }">
              {{ $format.dateBr(item.issue_date) }}
            </template>
            <template v-slot:[`item.due_date`]="{ item }">
              {{ $format.dateBr(item.due_date) }}
            </template>

            <template v-slot:[`item.value`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.value) }}
              </span>
            </template>
            <template v-slot:[`item.debt_balance`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.value - item.paid_value) }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-end">
        <v-col cols="6" class="text-right pr-3">
          <div>
            <b>Total de Original</b>
            {{ $format.decimal(debit_statement.total) }}
          </div>
          <div>
            <b>Total A pagar</b>
            {{ $format.decimal(debit_statement.open_total) }}
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";

export default {
  props: {
    debit_statement: {},
  },
  data() {
    return {
      date: format(new Date(), "dd/MM/yyyy"),

      headers: [
        { text: "Doc", value: "document", align: "center" },
        { text: "Emiss", value: "issue_date", align: "center" },
        { text: "Venc", value: "due_date", align: "center" },
        { text: "V. Original", value: "value", align: "center" },
        { text: "S. Devedor", value: "debt_balance", align: "center" },
        // { text: "Juros", value: "interest" },
      ],
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    bills() {
      return this.debit_statement.bills;
    },

    itensPerPage() {
      return this.itensCount;
    },

    itensCount() {
      if (this.bills) {
        return this.bills.length;
      }
      return 1;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },

    getItems(page) {
      let items = this.bills.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      if (items.length < this.itensPerPage) {
        for (let i = items.length; i < this.itensPerPage; i++) {
          items.push({});
        }
      }
      return items;
    },
  },
};
</script>
<style  lang="scss">
@import "@/assets/style/prints/ThermalPrinting.scss";
</style>